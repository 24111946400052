import React from 'react';
import GamesConnectPageTemplate from '../../../components/Connect/Games';
const subtitles = {
  A: (
    <>
      Tintash is a mobile and web development company that provides managed remote teams from around
      the globe. We work closely with you to design and build your digital products. Our clients
      include several <span className="connect__heading-description-bold">Startups</span>,{' '}
      <span className="connect__heading-description-bold">Unicorns</span> and{' '}
      <span className="connect__heading-description-bold">Fortune 500s.</span>
    </>
  ),
  B:
    'Leverage the Unity development talent to elevate your project, helping you achieve the dream outcomes you’ve always wanted.',
};
function index({location, pageContext}) {
  return (
    <GamesConnectPageTemplate
      variant={pageContext.variant}
      location={location}
      title="Top 1% Unity Development Company"
      subtitles={subtitles}
      metaTitle="Top 1% Unity Development Company | Tintash"
      metaDescription="Tintash is an industry leading Unity development company providing dedicated remote teams globally. We work closely with you to design and build your digital products. Our clients include Startups, Unicorns and Fortune 500s."
      pageviewTitle="Connect/Games/Unity.Development"
    />
  );
}

export default index;
